import React, { useState, useContext } from 'react';
import { useI18nContext, Trans } from '@ecg-marktplaats/js-react-i18n';

import Classes from './LoginComponent.scss';
import {
  Button,
  Card,
  TextField,
  PasswordTextField,
  UtilsBlock,
  ButtonsBlock,
  Link,
  Checkbox,
  TextRegular,
} from '@hz-design-system/web-ui';
import EnvironmentContext from '../../../contexts/EnvironmentContext';
import { ToastContainer } from 'react-toastify';

import { ClientError } from '@ecg-marktplaats/aurora-node-api-client';
import { trackGAEvent } from '../../../utils/gaTracking';
import useToastNotification from '../../../hooks/useToastNotification';
import type { TToastNotificationProps } from '../../../components/Toast/ToastNotification';
import loginSubmit from '../repositories/loginSubmit';
import {
  TWO_FACTOR_AUTH_SETUP_REQUIRED,
  TWO_FACTOR_AUTH_NEEDED,
  FORCE_PASSWORD_RESET,
  VERIFICATION_REQUESTS_BLOCKED,
  VERIFICATION_CODE_BLOCKED,
  GA_EVENTS,
  WRONG_USERNAME_PASSWORD,
} from '../../../../../common/constants';
import LoginGoogleButton from '../../../components/LoginGoogleButton/LoginGoogleButton';
import LoginTabs from '../../../components/LoginTabs/LoginTabs';
import useThreatMetrix from '../../../hooks/useThreatMetrix';
import useReblaze from '../../../hooks/useReblaze';

const LoginComponent = () => {
  const { threatMetrix, xsrfToken, reblaze, successUrl = '/' } = useContext(EnvironmentContext);
  const { t, tExists } = useI18nContext();
  const { toast } = useToastNotification<TToastNotificationProps>();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const TmxNoScript = useThreatMetrix(threatMetrix);
  useReblaze(reblaze?.challengeUrl);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      trackGAEvent(GA_EVENTS.LoginBegin);
      await loginSubmit({
        email,
        password,
        xsrfToken,
        rememberMe: remember,
        threatMetrix,
      });
      trackGAEvent(GA_EVENTS.LoginSuccess);
      window.location.assign(successUrl);
    } catch (error) {
      const errorMessage = (error as ClientError).message;
      const errorCode = (error as ClientError).code;
      const errorDetails = (error as ClientError).details;
      //TODO: add wrong username/password attempts counter
      if (errorDetails && (errorDetails as { code?: string }).code === WRONG_USERNAME_PASSWORD) {
        trackGAEvent(GA_EVENTS.LoginFailWrongUsernamePassword);
        toast({
          type: 'error',
          description: t('pages.login.validation.wrong_username_password'),
        });
        setIsLoading(false);
        return;
      }
      trackGAEvent(GA_EVENTS.LoginFail);
      let errorCheck = errorMessage;
      if (typeof errorCode === 'string') {
        errorCheck = errorCode;
      }
      switch (errorCheck) {
        case TWO_FACTOR_AUTH_SETUP_REQUIRED:
          window.location.assign('/identity/v2/two-factor-auth-setup');
          break;
        case TWO_FACTOR_AUTH_NEEDED:
          window.location.assign('/identity/v2/two-factor-auth-setup/challenge');
          break;
        case FORCE_PASSWORD_RESET:
          window.location.assign('/identity/v2/reset-password');
          break;
        case VERIFICATION_REQUESTS_BLOCKED:
        case VERIFICATION_CODE_BLOCKED:
          toast({ type: 'error', description: t('pages.login.validation.verification_requests_blocked') });
          break;
        default:
          const errorMsg = errorMessage?.toLowerCase();
          const errorText = tExists(`pages.login.validation.${errorMsg}`);
          if (errorText) {
            toast({ type: 'error', description: <Trans i18nKey={`pages.login.validation.${errorMsg}`} /> });
          } else {
            toast({ type: 'error', description: t('pages.login.validation.unknown_server_error') });
          }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleChange = () => {
    setRemember(!remember);
  };

  const handleSocialLoginClick = () => {
    setIsLoading(true);
  };

  const handleSocialError = () => {
    setIsLoading(false);
  };

  const onSuccess = () => {
    setIsLoading(false);
  };

  return (
    <div className={Classes.root}>
      <TmxNoScript />
      <h1>{t('pages.login.page_header')}</h1>
      <UtilsBlock pb="m" className={Classes.socialLogin}>
        <LoginGoogleButton onSuccess={onSuccess} onError={handleSocialError} onClick={handleSocialLoginClick} />
      </UtilsBlock>
      <Card style={{ textAlign: 'left' }}>
        <LoginTabs isLoginActive={true} />
        <UtilsBlock pl="l" pr="l" pb="m" pt="l">
          <TextField
            type="email"
            labelText={t('pages.login.email_label')}
            isRequired={true}
            onChange={(e: any) => setEmail(e.target.value)}
            id="email"
            value={email}
          />
        </UtilsBlock>
        <UtilsBlock pl="l" pr="l" pb="s">
          <PasswordTextField
            type="password"
            labelText={t('pages.login.password_label')}
            isRequired={true}
            onChange={(e: any) => setPassword(e.target.value)}
            showPasswordText={t('pages.new_password.show')}
            hidePasswordText={t('pages.new_password.hide')}
            id="password"
            value={password}
          />
        </UtilsBlock>
        <UtilsBlock pl="l" pr="l" pb="m" className={Classes.remember}>
          <div>
            <Checkbox
              id="remember_me"
              value=""
              className={Classes.remember}
              isChecked={remember}
              onChange={handleToggleChange}
              labelText={t('pages.login.stay_loggedin_label')}
            />
          </div>
          <Link href="/identity/v2/reset-password">{t('pages.login.password_recovery_link')}</Link>
        </UtilsBlock>
        <UtilsBlock pl="l" pr="l" pb="m">
          <TextRegular>{t('pages.login.public_computer')}</TextRegular>
          <TextRegular>{t('pages.login.public_computer_disclaimer')}</TextRegular>
        </UtilsBlock>
        <ButtonsBlock>
          <Button.Primary onClick={handleSubmit} type="button" disabled={isLoading}>
            {t('pages.login.login_email_address_button')}
          </Button.Primary>
        </ButtonsBlock>
      </Card>
      <UtilsBlock pt="m">
        {/* eslint-disable react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: t('pages.login.terms_and_conditions_disclaimer_links_no_fb') }} />
      </UtilsBlock>
      <ToastContainer />
    </div>
  );
};

export default LoginComponent;
