import React, { useEffect, useState } from 'react';
import loadScript from '../utils/loadScript';

const useThreatMetrix = (threatMetrix: any) => {
  const [hasProfilingScript, setHasProfilingScript] = useState(false);

  useEffect(() => {
    if (!hasProfilingScript && threatMetrix) {
      const loadThreatMetrixScript = async () => {
        try {
          await loadScript({
            url: `${threatMetrix?.domain}/fp/tags.js?org_id=udd8uxur&session_id=${threatMetrix?.uuid}&page_id=${threatMetrix?.pageID}`,
            id: 'profilingScript',
          });
          setHasProfilingScript(true);
        } catch (e) {
          // console.error('Failed to load ThreatMetrix script', e);
        }
      };

      loadThreatMetrixScript();
    }
  }, [hasProfilingScript, threatMetrix]);

  const iframeUrl = threatMetrix
    ? `${threatMetrix.domain}/fp/tags?org_id=udd8uxur&session_id=${threatMetrix.uuid}&page_id=${threatMetrix.pageID}`
    : '';

  const TmxNoScript = () => (
    <noscript>
      <iframe
        style={{ width: '100px', height: '100px', border: '0', position: 'absolute', top: '-5000px' }}
        src={iframeUrl}
      ></iframe>
    </noscript>
  );

  return TmxNoScript;
};

export default useThreatMetrix;
